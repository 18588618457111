<template>
  <div>
    <div class="text-center pt-3">
      <h1 class="font-weight-bolder text-white mb-6">Looking for Help?</h1>
      <div class="h4 text-white-50">
        Submit your help request to one of our Beyaz Games Experts!
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Send us your enquiries</h3>
        </div>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-xl-6">
              <b-form-group id="input-group-1" label="Name:" label-for="name-1">
                <b-form-input
                  id="name-1"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="Enter your name"
                  class="form-control form-control-solid form-control-lg"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Email address:"
                label-for="email-1"
                description="We'll never share your email with anyone else."
              >
                <b-form-input
                  id="email-1"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter email"
                  class="form-control form-control-solid form-control-lg"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Your Message:"
                label-for="message-1"
              >
                <b-form-textarea
                  id="message-1"
                  v-model="form.message"
                  type="textarea"
                  required
                  class="form-control form-control-solid form-control-lg"
                ></b-form-textarea>
              </b-form-group>
              <b-button
                @click="showDismissibleAlert = true"
                type="submit"
                variant="primary"
                >Send Us</b-button
              >
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div
          class="card card-custom mb-2 bg-diagonal bg-diagonal-light-primary"
        >
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between p-4">
              <div class="d-flex flex-column mr-5">
                <a href="#" class="h4 text-dark text-hover-warning mb-5"
                  >You can reach us by mail</a
                >
                <p class="text-dark-50">
                  You can contact us directly via e-mail.
                </p>
              </div>
              <div class="ml-6 flex-shrink-0">
                <a
                  href="mailto:support@beyazgames.com"
                  class="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
                  >Contact Us</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="card card-custom mb-2 bg-diagonal bg-diagonal-light-success"
        >
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between p-4">
              <div class="d-flex flex-column mr-5">
                <a href="#" class="h4 text-dark text-hover-warning mb-5"
                  >You can contact us via ticket</a
                >
                <p class="text-dark-50">
                  If you have a problem with the game you are playing, you
                  should send us a ticket.
                </p>
              </div>
              <div class="ml-6 flex-shrink-0">
                <a
                  href="https://support.beyazgames.com/newticket.php"
                  target="_blank"
                  class="btn font-weight-bolder text-uppercase font-size-lg btn-success py-3 px-6"
                  >Submit a Request</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: ""
      },
      show: true,
      sent: false,
      showDismissibleAlert: false
    };
  },
  components: {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      var instance = axios.create({
        headers: { "content-type": "application/x-www-form-urlencoded" }
      });
      instance
        .post("https://www.beyazgames.com/api.php", this.form)
        .then(res => {
          this.showDismissibleAlert = true;
          this.sent = true;
          this.show = false;
          alert("Thanks for contacting us!");
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contact", route: "alert" }
    ]);
  }
};
</script>
